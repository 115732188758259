<template>
<div>
       <Subtitle :title="title"></Subtitle>
      <GTable smallview :smallgames="games"></GTable>
</div>
</template>

<script>
const GTable = () => import("@/components/tips/add/games/GamesTable");
const Subtitle = () => import("@/components/common/Subtitle");

export default {
      name: "DashBoardTips",
      props: ["title", "games"],
      components: {
            GTable,
            Subtitle
      }
}
</script>
